import React from "react";
import { Layout, Seo } from "components/common";
import {Intro, Papers, Books, About} from "components/landing";

const Home = () => (
	<Layout lang={'en'} >
		<Seo lang={'en'} />
		<Intro lang={'en'} />
		<Books lang={'en'} />
		<Papers lang={'en'} />
		<About lang={'en'} />
	</Layout>
);

export default Home;
